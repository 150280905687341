hr {
  margin: 20px 0;
  background: #c0beb7;
  border-color: #c0beb7;
}

h2,
h3 {
  font-family: 'NeueMontreal';
  font-weight: 700;
}

h2 em,
h3 em {
  font-style: italic;
}

.inputControl {
  margin-bottom: 20px;
}

.inputControl__label,
.pseudoLabel {
  font-family: 'NeueMontreal';
  font-weight: 700;
  margin-bottom: 8px;
  display: block;
  opacity: .8;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
}

p.form-description {
  margin: 0 0 10px 0;
  font-size: 14px;
  line-height: 1.6em;
}

.inputControl input,
.inputControl select {
  padding: 6px 12px;
  width: calc(100% - 24px);
  background: #fcfcfc;
  outline: none;
  border: 1px solid #c0beb7;
  font-family: 'NeueMontreal';
  border-radius: 4px;
  -webkit-appearance: none;
}

.inputControl textarea {
  padding: 12px;
  width: calc(100% - 24px);
  font-family: 'NeueMontreal';
  border: 1px solid #c0beb7;
  border-radius: 6px;
  line-height: 1.6rem;
  resize: vertical;
}

.inputControl input:disabled,
.inputControl select:disabled,
.inputControl textarea:disabled {
  background: var(--theme-less-light);
}

.inputControl input.title {
  font-size: 20px;
}

.inputControl__help {
  font-size: 13px;
  margin-top: 8px;
  display: block;
  opacity: .68;
}

.binaryChooser {
  display: flex;
}

.binaryChooser__opt {
  padding: 4px 12px;
  vertical-align: top;
  background: #c0beb7;
}

.binaryControl.large .binaryChooser__opt {
  font-size: 20px;
  padding: 12px 24px;
}

.binaryControl.large .binaryChooser {
  justify-content: center;
}

.large .inputControl__label {
  font-size: 20px;
  text-align: center;
}

.binaryChooser__opt:first-child {
  border-radius: 4px 0 0 4px;
}

.binaryChooser__opt:last-child {
  border-radius: 0 4px 4px 0;
}

.binaryChooser__opt-chosen {
  background: #00374b;
  color: #fcfcfc;
}

.peopleChooser {
  margin-bottom: 20px;
}

.peopleChooserControls {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.peopleChooser__name {
  flex: 4;
}

.peopleChooser__title {
  flex: 2;
}

.peopleChooser__list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.peopleChooser__person {
  background: #00374b;
  color: #fcfcfc;
  padding: 4px 12px;
  display: flex;
  border-radius: 4px;
  gap: 24px;
}

img.imagePreview {
  max-width: 100%;
  display: block;
  margin-bottom: 10px;
}

.categoryList,
.relatedProducts {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  display: inline-flex;
  gap: 12px;
}

.relatedProducts {
  margin-top: 20px;
}

.categoryPill,
.relatedProduct {
  background: var(--theme-dark-accent);
  padding: 4px 12px;
  border-radius: 4px;
  border: 0;
  display: flex;
  gap: 8px;
}

.categoryPill-active {
  background: #00374b;
  color: #fcfcfc;
}

.rangeSlide {
  max-width: 100%;
}

.rangeSlider__track {
  display: flex;
  position: relative;
  gap: 0px;
  border-radius: 4px;
  margin-top: 12px;
}

.rangeSlider__min,
.rangeSlider__max {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #00374b;
  position: absolute;
  top: -2px;
}

.rangeSlider__label {
  position: absolute;
  top: 24px;
  font-size: 10px;
  padding: 2px 4px;
  background: #fcfcfc;
  text-align: center;
  border-radius: 2px;
}

.rangeSlider__stop {
  flex: 1;
  background: #b7bec0;
  height: 4px;
  margin-top: 5px;
}

.rangeSlider__stop.selected {
  background: #00374b
}

textarea.scrolly {
  height: 800px;
  resize: none;
  overflow-y: scroll;
}

.dataListWrapper {
  position: relative;
}

.matchList {
  position: absolute;
  top: 51px;
  left: 4px;
  width: calc(100% - 8px);
  background: #fcfcfc;
  z-index: 12;
  border-radius: 0 0 4px 4px;
}

.matchList.invisible {
  visibility: 0;
  height: 0px;
  overflow: hidden;
}

.matchOption {
  padding: 8px 8px;
  transition: all 0.2s ease;
  background: #fcfcfc;
  color: #000;
  font-size: 13px;
}

.matchOption:hover {
  cursor: pointer;
  background: #00374b;
  color: #fcfcfc;
}

@keyframes onAutoFillStart {}

@keyframes onAutoFillCancel {}

.input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

.input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}