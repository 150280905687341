.shippingRates {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.shippingRate {
  border-radius: 8px;
  margin-bottom: 6px;
  background: var(--theme-light);
  padding: 6px 18px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  cursor: pointer;
  border: 2px solid var(--theme-less-light);
}

.shippingRate:hover {
  background: var(--theme-less-light);
}

.shippingRate.selectedRate {
  border-color: var(--theme-red);
  background: #f3e7e7;
}

.shippingEstimate {
  color: #85837e;
  margin-top: 2px;
}

.shippingPrice {
  font-size: 16px;
  margin-bottom: 2px;
}

.shippingIcon {
  margin: auto 0;
}

.shippingIcon svg {
  width: 60px;
  max-height: 24px
}