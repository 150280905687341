.adminHeaderMenu {
  padding: 6px 40px;
  font-size: 14px;
  background: var(--theme-deep-blue);
  color: var(--theme-light);
  display: flex;
  gap: 20px;
}

.adminHeaderInner {
  display: flex;
  gap: 20px;
  width: fit-content;
}

.adminHeaderMenu a {
  color: var(--theme-light-blue);
}

.adminHeaderItem.title {
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.adminHeaderItem {
  position: relative;
}

.adminHeaderItem button {
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  margin: 0;
  font-size: 14px;
  font-family: NeueMontreal;
  color: var(--theme-light-blue);
}

.adminHeaderSubmenu {
  position: absolute;
  top: 20px;
  left: 0;
  background: var(--theme-deep-blue);
  width: 200px;
  z-index: 12;
}

.adminHeaderSubmenu__item {
  padding: 4px 8px;
}

.mobileMenuToggle {
  padding: 1px 10px;
  background: var(--theme-light-blue);
  outline: none;
  border: 0;
  border-radius: 4px;
}

@media (max-width: 800px) {
  .adminHeaderMenu {
    justify-content: space-between;
    padding: 6px 20px;
  }

  .adminHeaderInner {
    display: none;
  }

  .adminHeaderInner a,
  .adminHeaderItem {
    display: block;
    padding: 4px 0;

  }

  .adminHeaderInner.adminHeaderVisible {
    display: block;
    position: absolute;
    background: var(--theme-deep-blue);
    z-index: 10;
    padding: 20px;
    top: 36px;
    left: 0;
    width: calc(100% - 40px);
  }

  .adminHeaderSubmenu {
    position: unset;
  }
}