@font-face {
  font-family: 'ChronicleDisplayBold';
  src: url('./fonts/ChronicleDisp-Bold.otf');
}

@font-face {
  font-family: 'ChronicleTextItal';
  src: url('./fonts/ChronicleTextG1-Italic-Pro.otf');
}

@font-face {
  font-family: 'ChronicleDisplayBoldItal';
  src: url('./fonts/ChronicleDisp-BoldItal.otf');
}

@font-face {
  font-family: 'ChronicleRegular';
  src: url('./fonts/ChronicleTextG1-Roman-Pro.otf');
}

@font-face {
  font-family: 'NeueMontreal';
  src: url('./fonts/PPNeueMontreal-Variable.ttf');
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  src: url('./fonts/PPNeueMontreal-Variable.woff2') format('woff2'),
    url('./fonts/PPNeueMontreal-Variable.woff') format('woff'),
}

@font-face {
  font-family: 'NeueMontrealItalic';
  src: url('./fonts/PPNeueMontreal-Variable.ttf');
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  src: url('./fonts/PPNeueMontreal-Variable.woff2') format('woff2'),
    url('./fonts/PPNeueMontreal-Variable.woff') format('woff'),
}

:root {
  --theme-red: #e53b3c;
  --hover-red: #cd193d;
  --accessible-red: #E32B2B;
  --theme-deep-blue: #00374b;
  --hover-deep-blue: #012d3d;
  --theme-light-blue: #b9cdcc;
  --theme-deep-orange: #af945f;
  --hover-deep-orange: #8c7547;
  --theme-light: #fcfcfc;
  --theme-main-background: #f2f0ea;
  --theme-less-light: #e6e4dc;
  --theme-dark-accent: #c0beb7;
  --theme-off-black: #1c1c1c;
  --theme-green: #97a583;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.bold {
  font-family: 'NeueMontreal';
}

.app-container {
  height: max-content;
}

.app-container.cartOpen {
  height: 100%;
  overflow: hidden;
}

body {
  background: #f2f0ea;
  font-family: 'NeueMontreal';
  color: var(--theme-off-black);
}

strong {
  font-family: 'NeueMontreal';
  font-weight: 700;
}

a {
  color: var(--theme-deep-blue);
  text-decoration: none;
  opacity: 1.0;
  transition: all 0.2s ease;
}

a:hover {
  opacity: 0.9;
  text-decoration: underline;
}

.app-container {
  min-height: 100%;
}

.page-container {
  min-height: calc(100vh - 100px);
}

.content-container {
  padding: 40px;
  flex-grow: 1;
}

.content-container.full-width {
  padding: 0;
}

.pub-page .content-container {
  max-width: calc(100% - 324px);
}

.flex-layout {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-layout-small {
  gap: 10px;
}

.flex-layout-smallest {
  gap: 4px;
  justify-content: end;
}

.end {
  justify-content: end;
  display: inline-flex;
}

.if {
  display: inline-flex;
}

.flex-centered {
  margin: auto 0;
}

.flex-full {
  width: 100%;
}

.flex-quarter {
  flex: .25;
}

.flex-half {
  flex: .5;
}

.flex-one {
  flex: 1;
}

.flex-two {
  flex: 2;
}

.flex-three {
  flex: 3;
}

.flex-four {
  flex: 4;
}

.flex-five {
  flex: 5;
}

.flex-six {
  flex: 6;
}

.gridLayout {
  display: grid;
}

.gridColumns2 {
  grid-template-columns: repeat(2, 1fr);
}

.gridColumns3 {
  grid-template-columns: repeat(3, 1fr);
}

.gridColumns4 {
  grid-template-columns: repeat(4, 1fr);
}

.gridColumns5 {
  grid-template-columns: repeat(5, 1fr);
}

.gridColumns6 {
  grid-template-columns: repeat(6, 1fr);
}

.gridColumns7 {
  grid-template-columns: repeat(7, 1fr);
}

.gridColumns8 {
  grid-template-columns: repeat(8, 1fr);
}

.gridColumns9 {
  grid-template-columns: repeat(9, 1fr);
}

.gridColumns10 {
  grid-template-columns: repeat(10, 1fr);
}

.gridSpan2 {
  grid-column: span 2;
}

.gridSpan3 {
  grid-column: span 3;
}

.gridSpan4 {
  grid-column: span 4;
}

.gridSpan5 {
  grid-column: span 5;
}

.gridSpan6 {
  grid-column: span 6;
}

.gridGapLarge {
  gap: 40px;
}

.gridGapMedium {
  gap: 24px;
}

.gridGapSmall {
  gap: 12px;
}

.gridGapXS {
  gap: 6px;
}

.flexItem {
  display: flex;
}


.maxFullWidth {
  max-width: 100%;
}

h1.page-header {
  font-family: 'ChronicleDisplayBold';
  font-weight: normal;
  line-height: 1.7rem;
  margin-top: 0px;
}

.productPage__cartButton,
.buttonPrimary {
  background: none;
  border-radius: 2rem;
  border: 2px solid var(--theme-deep-blue);
  background: var(--theme-deep-blue);
  color: #f2f0ea;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.productPage__cartButton,
.buttonSecondary {
  background: none;
  border-radius: 2rem;
  border: 2px solid var(--theme-deep-blue);
  background: none;
  color: var(--theme-deep-blue);
  cursor: pointer;
  font-size: 14px;
  padding: 8px 12px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.buttonPrimary-small {
  background: none;
  border-radius: 2rem;
  border: 2px solid var(--theme-deep-blue);
  background: var(--theme-deep-blue);
  font-size: 13px;
  color: #f2f0ea;
  cursor: pointer;
  padding: 2px 8px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.buttonPrimary-large {
  background: none;
  border-radius: 6px;
  border: 2px solid var(--theme-deep-blue);
  background: var(--theme-deep-blue);
  font-size: 20px;
  color: #f2f0ea;
  cursor: pointer;
  padding: 12px 24px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.buttonSecondary-small {
  background: none;
  border-radius: 2rem;
  border: 2px solid var(--theme-deep-blue);
  background: none;
  font-size: 13px;
  color: var(--theme-deep-blue);
  cursor: pointer;
  padding: 2px 8px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.buttonSecondary-large {
  background: none;
  border-radius: 6px;
  border: 2px solid var(--theme-deep-blue);
  background: none;
  font-size: 20px;
  color: var(--theme-deep-blue);
  cursor: pointer;
  padding: 12px 24px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.buttonRed {
  background: var(--theme-red);
  color: var(--theme-main-background);
  border-color: var(--theme-red);
}

.buttonFullWidth {
  display: block;
  width: 100%;
  margin: 12px 0;
}

.grid-filters {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 28px;
}

.grid-filters.stores {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
}

.searchSingleProduct {
  display: flex;
  gap: 24px;
}

.selectedProduct {
  margin-top: 22px;
}

.shipmentList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 3fr 1fr;
  gap: 8px;
}

.bigButton {
  width: 100%;
}

.shipControlButtons {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.buttonPrimary:disabled,
.buttonSecondary:disabled,
.buttonPrimary-large:disabled,
.buttonSecondary-large:disabled {
  background: #b7bec0;
  border-color: #b7bec0;
  color: #657377;
  cursor: default;
}

.pseudoCheck {
  background: var(--theme-less-light);
  height: 14px;
  width: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  outline: none;
  border: 0
}

.pseudoCheck:hover {
  background: var(--theme-dark-accent);
}

.pseudoCheck.selected {
  background: var(--theme-red);
}

.pseudoCheck.selected:hover {
  background: var(--hover-red);
}

.reOrderButton {
  background: none;
  border-radius: 2rem;
  border: 2px solid var(--theme-deep-blue);
  background: none;
  font-size: 12px;
  color: var(--theme-deep-blue);
  cursor: pointer;
  padding: 0px 4px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  vertical-align: top;
  margin-left: 4px;
}

.asterismCard {
  background: #fcfcfc;
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 24px;
}

.asterismCard h4 {
  margin-top: 0;
}

.buttonCancel {
  background: none;
  border-radius: 2rem;
  border: 2px solid var(--theme-red);
  background: none;
  font-size: 13px;
  color: var(--theme-red);
  cursor: pointer;
  padding: 4px 12px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.buttonCancel-small {
  background: none;
  border-radius: 2rem;
  border: 2px solid #f13b3b;
  background: none;
  font-size: 13px;
  color: #f13b3b;
  cursor: pointer;
  padding: 2px 8px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.buttonPair {
  gap: 10px;
  display: flex;
}

input::file-selector-button {
  border: 2px solid var(--theme-deep-blue);
  background: none;
  color: var(--theme-deep-blue);
  padding: 4px 12px;
  border-radius: 4px;
}

.publisherWarning {
  padding: 20px;
  background: #fcfcfc;
  border: 2px solid #f13b3b;
  font-size: 14px;
  line-height: 1.6rem;
}

.publisherWarning button {
  margin-top: 20px;
}

p {
  line-height: 1.4em;
}

.adminRow,
.orderItem {
  border-bottom: 1px solid #b7bec0;
  padding: 10px 0;
}

.loginForm {
  max-width: 600px;
}

.passwordContainer {
  position: relative;
}

.passwordContainer button {
  border: 0;
  outline: none;
  background: none;
  position: absolute;
  right: 6px;
  cursor: pointer;
  top: 4px;
}


.loginCard {
  margin: 96px auto;
  width: max-content;
}

.fieldData {
  margin-bottom: 20px;
  line-height: 1.6rem;
}

.orderItemList {
  margin: 20px 0 40px;
}

.orderType,
.statusPill,
.orderStatus {
  padding: 4px 8px;
  color: #fcfcfc;
  font-size: 10px;
  text-transform: uppercase;
  font-family: 'NeueMontreal';
  font-weight: 600;
  letter-spacing: 1px;
  background: #000;
  display: inline-block;
  border-radius: 4px;
}


.statusPill.active {
  background: #29c390;
}

.orderType.typelibrary {
  background: var(--theme-light-blue);
}

.orderType.typeretail {
  background: var(--theme-deep-blue);
}

.orderType.typepublisher {
  background: #97a583;
}

.orderType.typedropship {
  background: var(--theme-deep-orange);
}

.orderType.typewholesale {
  background: var(--theme-red);
}

.orderListItem {
  margin-bottom: 6px;
}

.orderListItem__quantity,
.orderListItem__price,
.infoSmall {
  font-size: 12px;
  font-family: 'NeueMontreal';
  opacity: 0.8;
  background: #fcfcfc;
  padding: 2px;
  border-radius: 2px;
  margin-left: 4px;
}

.productList__image {
  max-width: 100%;
}

.productList__title {
  font-family: 'NeueMontreal';
  font-weight: 700;
  margin-bottom: 4px;
}

.productList__authors {
  font-size: 14px;
  opacity: 0.68;
}

.productList {
  font-size: 15px;
}

.roomAboveNone {
  margin-top: 0px !important;
}

.roomAboveLarge {
  margin-top: 40px !important;
}

.roomAboveMedium {
  margin-top: 20px !important;
}

.roomAboveSmall {
  margin-top: 10px !important;
}

.roomBelowLarge {
  margin-bottom: 40px !important;
}

.roomBelowMedium {
  margin-bottom: 20px !important;
}

.roomBelowXS {
  margin-bottom: 6px !important;
}

.roomLeftMedium {
  margin-left: 20px !important;
}

.roomBelowNone {
  margin-bottom: 0px !important;
}



.orderStatus.complete {
  background: #29c390;
}

.orderStatus.pending {
  background: var(--theme-deep-blue);
}

.orderStatus.billed {
  background: #f13b3b;
}

.overdue {
  background: #fcfcfc;
  color: #f13b3b;
  font-size: 12px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  padding: 1px 6px;
  border-radius: 2px;
  margin-left: 8px;
}

.prodNotice {
  background: #fcfcfc;
  color: #f13b3b;
  font-size: 12px;
  font-family: 'NeueMontreal';
  font-weight: 700;
  padding: 1px 6px;
  border-radius: 2px;
  margin: 2px 0;
  display: inline-block;
}

.directory_meta {
  font-family: 'NeueMontreal';
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
}

.closeIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
  display: inline-block;
}

.closeIcon-smaller {
  width: 8px;
  height: 8px;
}

.closeIcon:hover {
  opacity: 0.8;
}

.closeIcon svg line {
  fill: var(--theme-deep-blue) !important;
}

.closeIcon.closeIcon-light svg line {
  stroke: #f2f0ea !important;
}

.filters .inputControl {
  flex: 1;
}

.orderListItem {
  font-size: 15px;
  margin-bottom: 8px;
}

.bestSeller {
  margin-bottom: 6px;
}

.bestSellerNo {
  display: inline-block;
  width: 40px;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.bigStat {
  margin-bottom: 40px;
}

.bigStat__no {
  font-family: 'ChronicleDisplayBold';
  font-size: 42px;
}

.smallState__label {
  font-size: 20px;
}

.dateRange {
  font-family: 'NeueMontreal';
  font-weight: 700;
  opacity: 0.8;
  margin-top: 8px;
}

.orderLine {
  margin-bottom: 6px;
}

.orderLine__label {
  width: 80px;
  display: inline-block;
  font-family: 'NeueMontreal';
  font-weight: 700;
}

.orderTotal {
  border-top: 1px solid #b7bec0;
  padding-top: 8px;
  font-size: 22px;
  font-family: 'ChronicleDisplayBold';
}

.tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.tag {
  background-color: #657377;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 2rem;
  color: #fcfcfc;
}

.tagActive {
  background-color: var(--theme-deep-blue);
}

.note {
  background: #fcfcfc;
  padding: 12px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.noteMeta {
  font-size: 12px;
  opacity: 0.68;
}

.smallerText {
  font-size: 14px;
}

.vertTop {
  vertical-align: bottom;
}

.admin-text {
  line-height: 1.6em;
}

.pub-page {
  display: flex;
  gap: 24px;
}

.fireIcons {
  display: flex;
  gap: 4px;
  color: #e33b3c;
}

.asterismGridItem {
  display: grid;
  border-top: 1px solid #f2f0ea;
  background: #fcfcfc;
  font-size: 14px;
}

.asterismGridHeader {
  font-family: 'NeueMontreal';
  font-weight: 700;
  border-bottom: 1px solid #b7bec0;
  border-radius: 6px 6px 0 0;
}

.asterismGridHeaderSmaller {
  font-size: 14px;
}

.asterismGridSuperHeader {
  font-family: 'NeueMontreal';
  font-weight: 700;
  text-align: center;
  border-bottom: 1px solid #b7bec0;
  border-radius: 6px 6px 0 0;
}

.asterismGridItem:last-child {
  border-bottom: 0;
  border-radius: 0 0 6px 6px;
}

.asterismGridColumn {
  padding: 8px 12px;
}


.asterismGridColumn:last-child {
  border: 0;
}

.asterismGridColumn.left {
  border-left: 1px solid var(--theme-dark-accent);
}

.mobileShow {
  display: none;
}

@media (max-width: 800px) {

  .mobileShow {
    display: block;
  }

  .pub-page {
    flex-direction: column;
  }

  .pub-page .content-container {
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
  }

  .content-container {
    padding: 20px;
  }

  .adminRow {
    flex-direction: column;
  }

  .adminRow .flex-one {
    display: none;
  }

  .mobHide,
  .mobileHide {
    display: none;
  }
}